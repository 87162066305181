import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { containerMixin, responsiveQuery } from '../components/Layouts/LayoutWrap/GlobalStyles';

import Seo from '../components/Misc/Seo/Seo';
import MainLayout from '../components/Layouts/MainLayout/MainLayout';
import { Projects } from './index';
import { Heading, SectionHeader } from '../components/Misc/Typogrpahy/Typography';
import { SquareGridBackground } from '../components/Misc/Decorative/Decorative';

const SectionContent = styled.div`
  ${containerMixin};
`;
const ProjectsSection = styled.section`
  background: linear-gradient(0deg, #BFBBAC 43.15%, #F5F5F5 70.74%, rgba(245, 245, 245, 0) 100%);
  ${SquareGridBackground} {
    padding: 10rem 0 15rem;
    ${responsiveQuery.mobileL`
      padding: 5rem 0 10rem;
    `}
  }
`;

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query ProjectsPageQuery {
      site {
        siteMetadata {
          pages {
            projects {
              metaTitle
              projects {
                thumb
                name
                pagePath
              }
            }
          }
        }
      }
    }
  `);
  const {
    metaTitle,
    projects,
  } = data.site.siteMetadata.pages.projects;

  return (
    <MainLayout>
      <Seo title={metaTitle} />
      <ProjectsSection>
        <SquareGridBackground fadein='false' fadeOut={false}>
          <SectionContent>
            <SectionHeader>
              <Heading as='h2'>{metaTitle}</Heading>
            </SectionHeader>
            <Projects projects={projects} />
          </SectionContent>
        </SquareGridBackground>
      </ProjectsSection>
    </MainLayout>
  )
};

export default ProjectsPage;
